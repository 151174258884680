import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import { apikey } from '../../utils/stting';
import { errorToastify, successToastify } from '../../utils/cutomToastify';
import { CompanyModel } from '../../app/models/company.models';

const initialState: any = {
    isSuccess: false,
    loading: false,
    error: '',
    companies: [],
    addCompanies: [],
    deleteCompanies: [],
  }

  const API_URL = process.env.REACT_APP_API_URL


export const getCompanies = createAsyncThunk(
    'company/getCompanies',
    async (name, thunkAPI) => {
      try {
        const resp = await axios<CompanyModel>(`${API_URL}/Companys/GetList`, apikey);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  export const addCompany = createAsyncThunk(
    'company/addCompany',
    async (postData: any, thunkAPI) => {
      try {
        const resp = await axios.post(`${API_URL}/Companys/Add`, postData, apikey);
        successToastify('Company added successfully');
        return resp.data;
      } catch (error) {
        errorToastify('Something went wrong');
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async (postData: any, thunkAPI) => {
      try {
        const resp = await axios.delete(`${API_URL}/Companys/Delete`,{
          headers: {
            XApiKey  : "06vVEKVoanJq38K1GkguzvHb9pIZz5JNQTNn9EIl0a2ICyycxBOsqyCKxscc1sXU30lSMMO8FkXCqI5RfBHCzbpQdBozfTAi0sLnx5eHx4CKgWZ3VE6wgerydrQoZ00r"
          },
          data: postData
        });
        successToastify('Company deleted successfully');
        return resp.data;
      } catch (error) {
        errorToastify('Something went wrong');
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );
  


  const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {      
      resetCompanyState(state: any) {
        state.isSuccess = false;
      },
    },
    extraReducers: {
      // getCompanies
      [getCompanies.pending as any]: (state: any) => {
        state.isLoading = true;
      },
      [getCompanies.fulfilled as any]: (state: any, action: any) => {
        state.isLoading = false;
        state.companies = action.payload;
      },
      [getCompanies.rejected as any]: (state: any, action: any) => {
        state.isLoading = false;
      },

      // getCompanies
      [addCompany.pending as any]: (state: any) => {
        state.isLoading = true;
      },
      [addCompany.fulfilled as any]: (state: any, action: any) => {
        state.isLoading = false;
        state.addCompanies = action.payload;
        state.isSuccess = true;
      },
      [addCompany.rejected as any]: (state: any, action: any) => {
        state.isLoading = false;
      },

      // delete Companies
      [deleteCompany.pending as any]: (state: any) => {
        state.isLoading = true;
      },
      [deleteCompany.fulfilled as any]: (state: any, action: any) => {
        state.isLoading = false;
        state.deleteCompanies = action.payload;
        state.isSuccess = true;
      },
      [deleteCompany.rejected as any]: (state: any, action: any) => {
        state.isLoading = false;
      },
    }
    }
  )
  export const {resetCompanyState} = companySlice.actions;
export default companySlice.reducer;