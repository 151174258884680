import {configureStore} from '@reduxjs/toolkit'
import companyReducer from './slices/CompanySlice'
import customerReducer from './slices/CustomerSlice'
import categoryReducer from './slices/CategorySlice'
import productReducer from './slices/ProductSlice'


export const store = configureStore({
    reducer: {
      company: companyReducer,
      customer: customerReducer,
      category: categoryReducer,
      product: productReducer,
    }
  })