import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apikey } from '../../utils/stting';
import { errorToastify, successToastify } from '../../utils/cutomToastify';
import { CategoryModel } from '../../app/models/category.models';

const initialState: any = {
  isSuccess: false,
  loading: false,
  error: '',
  categories: [],
  addCustomer: [],
  deleteCustomer: [],
}

const API_URL = process.env.REACT_APP_API_URL

export const getCategory = createAsyncThunk(
  'company/getCategory',
  async (name, thunkAPI) => {
    try {
      const resp = await axios<CategoryModel>(`${API_URL}/ProductsCategories/GetList`, apikey);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addCategory = createAsyncThunk(
  'company/addCategory',
  async (postData: any, thunkAPI) => {
    try {
      const resp = await axios.post(`${API_URL}/ProductsCategories/Add`, postData, apikey);
      successToastify('Kategori başarıyla eklendi');
      return resp.data;
    } catch (error) {
      errorToastify('Kategori eklenemedi');
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'company/deleteCategory',
  async (postData: any, thunkAPI) => {
    try {
      const resp = await axios.delete(`${API_URL}/ProductsCategories/Delete`, {
        headers: {
          XApiKey: "06vVEKVoanJq38K1GkguzvHb9pIZz5JNQTNn9EIl0a2ICyycxBOsqyCKxscc1sXU30lSMMO8FkXCqI5RfBHCzbpQdBozfTAi0sLnx5eHx4CKgWZ3VE6wgerydrQoZ00r"
        },
        data: postData
      });
      successToastify('Kategori başarıyla silindi');
      return resp.data;
    } catch (error) {
      errorToastify('Kategori Silinemedi');
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);


const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategorySlice(state: any) {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    // getCategory
    [getCategory.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [getCategory.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.categories = action.payload;
    },
    [getCategory.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },

    // addCategory
    [addCategory.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [addCategory.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.categories = action.payload;
      state.isSuccess = true;
    },
    [addCategory.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },

    // deleteCategory
    [deleteCategory.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [deleteCategory.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.deleteCustomer = action.payload;
      state.isSuccess = true;
    },
    [deleteCategory.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },
  }
}
)
export const { resetCategorySlice } = categorySlice.actions;
export default categorySlice.reducer;