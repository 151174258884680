import axios from 'axios'
import {AuthModel, UserModel} from './_models'


const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/User/Login`
export const REGISTER_URL = `${API_URL}/User/Register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

let apiKey = {
  headers: {
    XApiKey: '06vVEKVoanJq38K1GkguzvHb9pIZz5JNQTNn9EIl0a2ICyycxBOsqyCKxscc1sXU30lSMMO8FkXCqI5RfBHCzbpQdBozfTAi0sLnx5eHx4CKgWZ3VE6wgerydrQoZ00r'
  }
}
// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  }, apiKey)
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  phoneNumber: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
    phoneNumber,
  }, apiKey)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(userId: string) {
  return axios.get<UserModel>(`${API_URL}/User/GetBy?Id=${userId}`,apiKey)
}
