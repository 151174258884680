/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { Formik } from 'formik';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { getCompanies } from '../../../../redux/slices/CompanySlice';
import Input from '../../../component/InputComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addCustomer } from '../../../../redux/slices/CustomerSlice';

const AddCustomer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [companyId, setCompanyId] = useState<any>('')
  const { companies } = useSelector((store: any) => store.company)
  const { isSuccess } = useSelector((store: any) => store.customer)
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getCompanies());
    if (isSuccess) {
      navigate('/customer')
    }
  }, [dispatch, isSuccess, navigate])


  return (
    <>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          companyId: "",
          phoneNumber: 0,
          companyName: "",
          address: "",
          cityId: "",
          countryId: "",
          kvkNumber: 0,
          btwNumber: ""
        }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          values.companyId = companyId;
          setTimeout(() => {
            resetForm();
          }, 2000)

          dispatch(addCustomer(values))
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting }) => (
          <div>
              <div>
                <form onSubmit={handleSubmit}>

                  <div className='d-flex flex-column gap-5'>
                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design field">
                        <label htmlFor="title">{t('first-name')}</label>
                        <Input placeholder={t('first-name')} id='firstname' name='firstname' value={values.firstname} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                      <div className='form__group-design'>
                        <label htmlFor="email">{t('last-name')}</label>
                        <Input id='lastname' name='lastname' placeholder={t('last-name')} value={values.lastname} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                    </div>
                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design">
                        <label htmlFor="phoneNumber">{t('phone-number')}</label>
                        <PhoneInput
                          className='w-100'
                          defaultCountry="ua"
                          value={values.phoneNumber}
                          onChange={(phone: any) => console.log(phone)}
                        />
                      </div>
                      <div className='form__group-design'>
                        <label htmlFor="zipCode">E-Mail</label>
                        <Input id='email' name='email' placeholder="E-Mail" value={values.email} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                    </div>

                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design field">
                        <label className='form__label'>{t('company-name')}</label>
                        <select onChange={(e: any) => { setCompanyId(e.target.value) }} className="custom-input-component selectpicker">
                          <option>Seçiniz</option>
                          {companies && companies?.map((item: any) => {
                            return (
                              <option key={item.id} value={item.id}>{item.title}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design field">
                        <label className='form__label'>Ülke</label>
                        <select className="custom-input-component selectpicker">
                          <option>Mustard</option>
                          <option>Ketchup</option>
                          <option>Relish</option>
                        </select>
                      </div>
                      <div className="form__group-design field">
                        <label className='form__label'>Şehir</label>
                        <select className="custom-input-component selectpicker">
                          <option>Mustard</option>
                          <option>Ketchup</option>
                          <option>Relish</option>
                        </select>
                      </div>
                    </div>

                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design field">
                        <label htmlFor="iban">{t('company-name')}</label>
                        <Input id='companyName' name='companyName' placeholder={t('company-name')} value={values.companyName} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                      <div className='form__group-design'>
                        <label htmlFor="kvkNumber">KVK Number</label>
                        <Input id='kvkNumber' name='kvkNumber' placeholder="KVK Number" value={values.kvkNumber} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                    </div>

                    <div className='d-flex align-items-center gap-5'>
                      <div className="form__group-design field">
                        <label htmlFor="btwNumber">BTW Number</label>
                        <Input id='btwNumber' name='btwNumber' placeholder="BTW Number" value={values.btwNumber} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                      <div className='form__group-design'>
                        <label htmlFor="address">Adress</label>
                        <Input id='address' name='address' placeholder="Adress" value={values.address} onChange={handleChange} onBlur={handleBlur} required />
                      </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button className='btn btn-success' type='submit'>Save Company</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        )}
      </Formik>
    </>
  )
}

const AddCustomerWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Add Company'}</PageTitle>
      <AddCustomer />
    </>
  )
}

export { AddCustomerWrapper }
