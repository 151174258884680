import clsx from 'clsx'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useTranslation } from 'react-i18next';


const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { currentUser, logout } = useAuth()
  const { config } = useLayout()
  const { t, i18n } = useTranslation();

  return (
    <div className='d-flex justify-content-end align-items-center language-dropdown gap-5'>
      <div className="dropdown">
        <input type="checkbox" id="dropdown" />

        <label className="dropdown__face" htmlFor="dropdown">
          <div className="dropdown__text">{i18n.language}</div>

          <div className="dropdown__arrow"></div>
        </label>

        <ul className="dropdown__items border">
          <button onClick={(e: any) => {i18n.changeLanguage(e.target.value)}} value="tr">TR</button>
          <button onClick={(e: any) => {i18n.changeLanguage(e.target.value)}} value="en">EN</button>
          <button onClick={(e: any) => {i18n.changeLanguage(e.target.value)}} value="nl">NL</button>
        </ul>
      </div>

      <button onClick={logout} className='btn btn-light btn-lg' style={{width: "125px"}}>{t('logout')}</button>
    </div>
  )
}

export { Navbar }
