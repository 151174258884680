/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { Formik } from 'formik';
import { getCompanies } from '../../../../redux/slices/CompanySlice';
import Input from '../../../component/InputComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCategory } from '../../../../redux/slices/CategorySlice';
import { addProduct } from '../../../../redux/slices/ProductSlice';

const AddProduct: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [companyId, setCompanyId] = useState<any>('')
  const [categoryId, setCategoryId] = useState<any>('')
  const { companies } = useSelector((store: any) => store.company)
  const { categories } = useSelector((store: any) => store.category)
  const { isSuccess } = useSelector((store: any) => store.product)
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getCategory())
    if (isSuccess) {
      navigate('/product')
    }
  }, [dispatch, isSuccess, navigate])

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          companyId: '',
          categoryId: '',
          price: 0,
          listPrice: 0,
        }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          values.companyId = companyId;
          values.categoryId = categoryId;
          setTimeout(() => {
            resetForm();
          }, 2000)

          dispatch(addProduct(values))
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting }) => (
          <div>
            <div>
              <form onSubmit={handleSubmit}>

                <div className='d-flex flex-column gap-5'>
                  <div className='d-flex align-items-center gap-5'>
                    <div className="form__group-design field">
                      <label htmlFor="name">{t('product-name')}</label>
                      <Input placeholder={t('product-name')} id='name' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} required />
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-5'>
                  <div className="form__group-design field" style={{marginTop: '30px'}}>
                      <label className='form__label'>{t('company-name')}</label>
                      <select onChange={(e: any) => { setCompanyId(e.target.value) }} className="custom-input-component selectpicker">
                        <option>Seçiniz</option>
                        {companies && companies?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="form__group-design field" style={{marginTop: '30px'}}>
                      <label className='form__label'>{t('category-name')}</label>
                      <select onChange={(e: any) => { setCategoryId(e.target.value) }} className="custom-input-component selectpicker">
                        <option>Seçiniz</option>
                        {categories && categories?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='d-flex align-items-center gap-5'>
                    <div className="form__group-design field">
                      <label htmlFor="price">{t('price')}</label>
                      <Input placeholder={t('price')} id='price' name='price' value={values.price} onChange={handleChange} onBlur={handleBlur} required />
                    </div>
                    <div className="form__group-design field">
                      <label htmlFor="listPrice">{t('list-price')}</label>
                      <Input placeholder={t('list-price')} id='listPrice' name='listPrice' value={values.listPrice} onChange={handleChange} onBlur={handleBlur} required />
                    </div>
                  </div>

                  <div className='d-flex justify-content-end'>
                    <button className='btn btn-success' type='submit'>{t('save')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

const AddProductWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Add Product'}</PageTitle>
      <AddProduct />
    </>
  )
}

export { AddProductWrapper }
