import React, { ChangeEvent, FC } from 'react';

type InputProps = {
  id?: string;
  name?: string;
  placeholder?: any;
  value?: string;
  className?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Input: FC<InputProps> = ({ id, name, placeholder, value, className, required, onChange,onBlur }) => {
  return (
    <input
      id={id}
      name={name}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
      className={`${className} custom-input-component`}
    />
  );
};

export default Input;