/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ListsWidget26, ListsWidget6} from '../../../_metronic/partials/widgets'
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../modules/auth';


const DashboardPage: FC = () => {
  const dispatch = useDispatch<any>();
  const {currentUser} = useAuth()
  console.log(currentUser)
  useEffect(() => {
  }, [dispatch])
  return(
  <>
    <div className='d-flex gap-5 g-xl-10 mb-5 mb-xl-10 flex-wrap'>
      <ListsWidget26 title='Transacties' className='' options={[{link:'/', value: 'Verkoop Transacties'}, {link:'/dashboard', value: 'Inkoop Transacties'}]}/>
      <ListsWidget26 title='Operaties' className='' options={[{link:'/', value: 'Facturen'}, {link:'/dashboard', value: 'Offertes'}, {link:'/dashboard', value: 'Bestelling Afdrukken'}, {link:'/dashboard', value: 'Klant Nota Afdrukken'}, {link:'/dashboard', value: 'Snel Verkoop'}]} />
      <ListsWidget26 title='Promoties' className='' options={[{link:'/', value: 'Promoties'}, {link:'/dashboard', value: 'Services'}, {link:'/dashboard', value: 'Add Service'}]}/>
      <ListsWidget26 title='Promoties' className='' options={[{link:'/', value: 'Promoties'}, {link:'/dashboard', value: 'Services'}, {link:'/dashboard', value: 'Add Service'}]}/>
      <ListsWidget6 className='' options={[{link:'/', value: 'flex apps'}]}/>
    </div>
  </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
