/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { Formik } from 'formik';
import { getCompanies } from '../../../../redux/slices/CompanySlice';
import Input from '../../../component/InputComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addCategory } from '../../../../redux/slices/CategorySlice';

const AddCategory: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [companyId, setCompanyId] = useState<any>('')
  const { companies } = useSelector((store: any) => store.company)
  const { isSuccess } = useSelector((store: any) => store.category)
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getCompanies());
    if (isSuccess) {
      navigate('/categories')
    }
  }, [dispatch, isSuccess, navigate])


  return (
    <>
      <Formik
        initialValues={{
          title: '',
          description: '',
          companyId: '',
        }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          values.companyId = companyId;
          setTimeout(() => {
            resetForm();
          }, 2000)

          dispatch(addCategory(values))
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting }) => (
          <div>
            <div>
              <form onSubmit={handleSubmit}>

                <div className='d-flex flex-column gap-5'>
                  <div className='d-flex align-items-center gap-5'>
                    <div className="form__group-design field">
                      <label htmlFor="title">{t('first-name')}</label>
                      <Input placeholder={t('first-name')} id='title' name='title' value={values.title} onChange={handleChange} onBlur={handleBlur} required />
                    </div>
                    <div className="form__group-design field" style={{marginTop: '30px'}}>
                      <label className='form__label'>{t('company-name')}</label>
                      <select onChange={(e: any) => { setCompanyId(e.target.value) }} className="custom-input-component selectpicker">
                        <option>Seçiniz</option>
                        {companies && companies?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='form__group-design'>
                    <label htmlFor="description">{t('description')}</label>
                    <Input id='description' name='description' placeholder={t('description')} value={values.description} onChange={handleChange} onBlur={handleBlur} required />
                  </div>

                  <div className='d-flex justify-content-end'>
                    <button className='btn btn-success' type='submit'>{t('save')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

const AddCategoryWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Add Category'}</PageTitle>
      <AddCategory />
    </>
  )
}

export { AddCategoryWrapper }
