import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apikey } from '../../utils/stting';
import { errorToastify, successToastify } from '../../utils/cutomToastify';
import { ProductModel } from '../../app/models/product.models';

const initialState: any = {
    isSuccess: false,
    loading: false,
    error: '',
    products: [],
    addProduct: [],
    deleteProduct: [],
}

const API_URL = process.env.REACT_APP_API_URL

export const getProduct = createAsyncThunk(
    'product/getProduct',
    async (name, thunkAPI) => {
        try {
            const resp = await axios<ProductModel>(`${API_URL}/Product/Get`, apikey);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    }
);
export const addProduct = createAsyncThunk(
    'product/addProduct',
    async (postData: any, thunkAPI) => {
        try {
            const resp = await axios.post(`${API_URL}/Product/Add`, postData, apikey);
            successToastify('Ürün başarıyla eklendi');
            return resp.data;
        } catch (error) {
            errorToastify('Ürün eklenemedi');
            return thunkAPI.rejectWithValue('something went wrong');
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'product/deleteProduct',
    async (postData: any, thunkAPI) => {
        try {
            const resp = await axios.delete(`${API_URL}/Product/Delete`, {
                headers: {
                    XApiKey: "06vVEKVoanJq38K1GkguzvHb9pIZz5JNQTNn9EIl0a2ICyycxBOsqyCKxscc1sXU30lSMMO8FkXCqI5RfBHCzbpQdBozfTAi0sLnx5eHx4CKgWZ3VE6wgerydrQoZ00r"
                },
                data: postData
            });
            successToastify('Ürün başarıyla silindi');
            return resp.data;
        } catch (error) {
            errorToastify('Ürün Silinemedi');
            return thunkAPI.rejectWithValue('something went wrong');
        }
    }
);


const producrSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetProductSlice(state: any) {
            state.isSuccess = false;
        },
    },
    extraReducers: {
        // getCategory
        [getProduct.pending as any]: (state: any) => {
            state.isLoading = true;
        },
        [getProduct.fulfilled as any]: (state: any, action: any) => {
            state.isLoading = false;
            state.products = action.payload;
        },
        [getProduct.rejected as any]: (state: any, action: any) => {
            state.isLoading = false;
        },

        // addProduct
        [addProduct.pending as any]: (state: any) => {
            state.isLoading = true;
        },
        [addProduct.fulfilled as any]: (state: any, action: any) => {
            state.isLoading = false;
            state.addProduct = action.payload;
            state.isSuccess = true;
        },
        [addProduct.rejected as any]: (state: any, action: any) => {
            state.isLoading = false;
        },

        // deleteProduct
        [deleteProduct.pending as any]: (state: any) => {
            state.isLoading = true;
        },
        [deleteProduct.fulfilled as any]: (state: any, action: any) => {
            state.isLoading = false;
            state.deleteProduct = action.payload;
            state.isSuccess = true;
        },
        [deleteProduct.rejected as any]: (state: any, action: any) => {
            state.isLoading = false;
        },
    }
}
)
export const { resetProductSlice } = producrSlice.actions;
export default producrSlice.reducer;