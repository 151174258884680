import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apikey } from '../../utils/stting';
import { errorToastify, successToastify } from '../../utils/cutomToastify';
import { CustomerModel } from '../../app/models/customer.model';

const initialState: any = {
  isSuccess: false,
  loading: false,
  error: '',
  customers: [],
  addCustomers: [],
  deleteCustomers: [],
}

const API_URL = process.env.REACT_APP_API_URL

export const getCustomer = createAsyncThunk(
  'company/getCustomer',
  async (name, thunkAPI) => {
    try {
      const resp = await axios<CustomerModel>(`${API_URL}/Customer/GetList`, apikey);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const addCustomer = createAsyncThunk(
  'company/addCustomer',
  async (postData: any, thunkAPI) => {
    try {
      const resp = await axios.post(`${API_URL}/Customer/Add`, postData, apikey);
      successToastify('Müşteri başarıyla eklendi');
      return resp.data;
    } catch (error) {
      errorToastify('Müşteri eklenemedi');
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'company/deleteCustomer',
  async (postData: any, thunkAPI) => {
    try {
      const resp = await axios.delete(`${API_URL}/Customer/Delete`, {
        headers: {
          XApiKey: "06vVEKVoanJq38K1GkguzvHb9pIZz5JNQTNn9EIl0a2ICyycxBOsqyCKxscc1sXU30lSMMO8FkXCqI5RfBHCzbpQdBozfTAi0sLnx5eHx4CKgWZ3VE6wgerydrQoZ00r"
        },
        data: postData
      });
      successToastify('Müşteri başarıyla silindi');
      return resp.data;
    } catch (error) {
      errorToastify('Müşteri Silinemedi');
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);


const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCutomerReduce(state: any) {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    // get customer
    [getCustomer.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [getCustomer.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.customers = action.payload;
    },
    [getCustomer.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },
    // add customer
    [addCustomer.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [addCustomer.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.addCustomers = action.payload;
      state.isSuccess = true;
    },
    [addCustomer.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },
    // delete customer
    [deleteCustomer.pending as any]: (state: any) => {
      state.isLoading = true;
    },
    [deleteCustomer.fulfilled as any]: (state: any, action: any) => {
      state.isLoading = false;
      state.deleteCustomers = action.payload;
      state.isSuccess = true;
    },
    [deleteCustomer.rejected as any]: (state: any, action: any) => {
      state.isLoading = false;
    },
  }
}
)
export const { resetCutomerReduce } = customerSlice.actions;
export default customerSlice.reducer;