/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { deleteCustomer, getCustomer, resetCutomerReduce } from '../../../redux/slices/CustomerSlice';
import { CustomerModel } from '../../models/customer.model';

const CustomerList: FC = () => {
  const dispatch = useDispatch<any>();

  const { customers, deleteCustomers } = useSelector((store: any) => store.customer)
  useEffect(() => {
    dispatch(getCustomer());
    dispatch(resetCutomerReduce());
  }, [dispatch, deleteCustomers])

  const { t } = useTranslation();

  const customerColumns = useMemo<MRT_ColumnDef<CustomerModel>[]>(
    () => [
      {
        accessorKey: 'firstname',
        header: 'First Name',
      },
      {
        accessorKey: 'lastname',
        header: 'Last Name',
      },
      {
        accessorKey: 'companyName',
        header: 'Company Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },
      {
        accessorKey: 'kvkNumber',
        header: 'KVK Number',
      },
      {
        accessorKey: 'btwNumber',
        header: 'BTW Number',
      },
      {
        accessorKey: 'address',
        header: 'Address',
      }
    ],
    [],
  );

  return (
    <>
      <div>
        {customers.length > 0 && (
          <div className='d-flex justify-content-end align-items-center mb-5'>
            <Link to='/customer/add-customer' className='btn btn-primary'>{t('add-customer')}</Link>
          </div>
        )}
        <div>
          {customers.length > 0 ? (
            <MaterialReactTable columns={customerColumns} data={customers}
              enableRowActions
              positionActionsColumn="last"
              getRowId={(row) => row.id}
              renderRowActions={({ row }) => (
                <Box>
                  <Button onClick={() => { dispatch(deleteCustomer({ id: row.id, isDeleted: true })) }}>{t('delete')}</Button>
                </Box>
              )}
            />) : (
            <div className='card p-5'>
              <div className='d-flex justify-content-center align-items-center'>
                <h3 style={{ color: "GrayText" }}>{t('have-not-customer')}</h3>
              </div>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <Link to='/customer/add-customer' className='btn btn-primary'>{t('add-customer')}</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const CustomerListWrapper: FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{t('companies')}</PageTitle>
      <CustomerList />
    </>
  )
}

export { CustomerListWrapper }
