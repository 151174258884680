import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  tr: {
    translation: {
      "home": "Anasayfa",
      "customer": "Müşteriler",
      "register": "Kayıt Ol",
      "login": "Giriş Yap",
      "product-categories": "Ürün Kategorileri",
      "delete": "Sil",
      "password": "Şifre",
      "forgot-password": "Şifremi Unuttum",
      "please-wait": "Lütfen bekleyin...",
      "companies": "Şirketler",
      "company-name": "Şirket Adı",
      "first-name": "Ad",
      "last-name": "Soyad",
      "phone-number": "Telefon Numarası",
      "success-add-customer": "Müşteri başarıyla eklendi",
      "zip-code": "Posta Kodu",
      "add-company": "Şirket Ekle",
      "logout": "Çıkış Yap",
      "have-not-company": "Şirketiniz yok. Lütfen şirket ekleyin.",
      "add-customer": "Müşteri Ekle",
      "have-not-customer": "Müşteriniz yok. Lütfen müşteri ekleyin.",
      "suppliers": "Tedarikçiler",
      "groups": "Gruplar",
      "users": "Kullanıcılar",
      "products" : "Ürünler",
      "sales": "Satışlar",
      "sales-invoices": "Satış Faturaları",
      "purchase-invoices": "Alış Faturaları",
      "reports": "Raporlar",
      "payments": "Ödemeler",
      "offers": "Teklifler",
      "settings": "Ayarlar",
      "add-categories": "Kategori Ekle",
      "have-not-category": "Kategori yok. Lütfen kategori ekleyin.",
      "save": "Kaydet",
      "description": "Açıklama",
      "have-not-product": "Ürün yok. Lütfen ürün ekleyin.",
      "add-product": "Ürün Ekle",
      "category-name": "Kategori Adı",
      "price": "Fiyat",
      "list-price": "Liste Fiyatı",
      "product-name": "Ürün Adı",
    }
  },
  en: {
    translation: {
      "home": "Home",
      "customer": "Customers",
      "register": "Register",
      "product-categories": "Product Categories",
      "login": "Login",
      "delete": "Delete",
      "password": "Password",
      "forgot-password": "Forgot Password",
      "please-wait": "Please wait...",
      "companies": "Companies",
      "first-name": "First Name",
      "last-name": "Last Name",
      "company-name": "Company Name",
      "phone-number": "Phone Number",
      "zip-code": "Zip Code",
      "success-add-customer": "Customer added successfully",
      "add-company": "Add Company",
      "logout": "Logout",
      "have-not-company": "You have not company yet. Please add company.",
      "add-customer": "Add Customer",
      "have-not-customer": "You have not customer yet. Please add customer.",
      "suppliers": "Suppliers",
      "groups": "Groups",
      "users": "Users",
      "products" : "Products",
      "sales": "Sales",
      "sales-invoices": "Sales Invoices",
      "purchase-invoices": "Purchase Invoices",
      "reports": "Reports",
      "payments": "Payments",
      "offers": "Offers",
      "settings": "Settings",
      "add-categories": "Add Categories",
      "have-not-category": "You have not category yet. Please add category.",
      "save": "Save",
      "description": "Description",
      "have-not-product": "You have not product yet. Please add product.",
      "add-product": "Add Product",
      "category-name": "Category Name",
      "price": "Price",
      "list-price": "List Price",
      "product-name": "Product Name",
    }
  },
  nl: {
    translation: {
      "home": "Home",
      "customer": "Klanten",
      "product-categories": "Productcategorieën",
      "register": "Registreren",
      "login": "Inloggen",
      "password": "Wachtwoord",
      "delete": "Verwijderen",
      "forgot-password": "Wachtwoord vergeten",
      "please-wait": "Even geduld aub...",
      "companies": "Bedrijven",
      "first-name": "Voornaam",
      "last-name": "Achternaam",
      "company-name": "Bedrijfsnaam",
      "phone-number": "Telefoonnummer",
      "success-add-customer": "Klant succesvol toegevoegd",
      "zip-code": "Postcode",
      "add-company": "Voeg bedrijf toe",
      "logout": "Uitloggen",
      "have-not-company": "U heeft nog geen bedrijf. Voeg een bedrijf toe.",
      "add-customer": "Voeg klant toe",
      "have-not-customer": "U heeft nog geen klant. Voeg een klant toe.",
      "suppliers": "Leveranciers",
      "groups": "Groepen",
      "users": "Gebruikers",
      "products" : "Producten",
      "sales": "Verkopen",
      "sales-invoices": "Verkoopfacturen",
      "purchase-invoices": "Aankoopfacturen",
      "reports": "Rapporten",
      "payments": "Betalingen",
      "offers": "Aanbiedingen",
      "settings": "Instellingen",
      "add-categories": "Categorieën toevoegen",
      "have-not-category": "U heeft nog geen categorie. Voeg een categorie toe.",
      "save": "Opslaan",
      "description": "Beschrijving",
      "have-not-product": "U heeft nog geen product. Voeg een product toe.",
      "add-product": "Product toevoegen",
      "category-name": "Categorie naam",
      "price": "Prijs",
      "list-price": "Lijstprijs",
      "product-name": "Productnaam",
    }
  },
  de: {
    translation: {
      "home": "Home",
      "customer": "Kunden",
      "product-categories": "Produktkategorien",
      "register": "Registrieren",
      "login": "Anmeldung",
      "password": "Passwort",
      "delete": "Löschen",
      "forgot-password": "Passwort vergessen",
      "please-wait": "Bitte warten...",
      "companies": "Unternehmen",
      "first-name": "Vorname",
      "last-name": "Nachname",
      "sales": "Verkäufe",
      "sales-invoices": "Verkaufsrechnungen",
      "purchase-invoices": "Einkaufsrechnungen",
      "reports": "Berichte",
      "payments": "Zahlungen",
      "offers": "Angebote",
      "settings": "Einstellungen",
      "company-name": "Firmenname",
      "phone-number": "Telefonnummer",
      "success-add-customer": "Kunde erfolgreich hinzugefügt",
      "zip-code": "Postleitzahl",
      "add-company": "Firma hinzufügen",
      "logout": "Ausloggen",
      "have-not-company": "Sie haben noch kein Unternehmen. Bitte fügen Sie ein Unternehmen hinzu.",
      "add-customer": "Kunde hinzufügen",
      "have-not-customer": "Sie haben noch keinen Kunden. Bitte fügen Sie einen Kunden hinzu.",
      "suppliers": "Lieferanten",
      "groups": "Gruppen",
      "users": "Benutzer",
      "products" : "Produkte",
      "add-categories": "Kategorien hinzufügen",
      "have-not-category": "Sie haben noch keine Kategorie. Bitte fügen Sie eine Kategorie hinzu.",
      "save": "Speichern",
      "description": "Beschreibung",
      "have-not-product": "Sie haben noch kein Produkt. Bitte fügen Sie ein Produkt hinzu.",
      "add-product": "Produkt hinzufügen",
      "category-name": "Kategorie Name",
      "price": "Preis",
      "list-price": "Listenpreis",
      "product-name": "Produktname",
    }
  }
}

i18n
  .use(initReactI18next)
  .init({
    lng: 'tr',
    resources
  })

export default i18n;