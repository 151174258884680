/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo } from 'react';
import { PageTitle } from '../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany } from '../../../redux/slices/CompanySlice';
import { Link } from 'react-router-dom';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ProductModel } from '../../models/product.models';
import { getProduct, resetProductSlice } from '../../../redux/slices/ProductSlice';

const ProductList: FC = () => {
  const dispatch = useDispatch<any>();

  const { products } = useSelector((store: any) => store.product)
  useEffect(() => {
    dispatch(getProduct());
    dispatch(resetProductSlice());
  }, [dispatch])

  const { t } = useTranslation();

  const ProductColumns = useMemo<MRT_ColumnDef<ProductModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
      },
      {
        accessorKey: 'price',
        header: 'Product Price',
      },
      {
        accessorKey: 'listPrice',
        header: 'Product List Price',
      },
    ],
    [],
  );

  return (
    <>
      <div>
        {products.length > 0 && (
          <div className='d-flex justify-content-end align-items-center mb-5'>
            <Link to='/product/add-product' className='btn btn-primary'>{t('add-product')}</Link>
          </div>
        )}
        <div>
          {products.length > 0 ? (
            <MaterialReactTable columns={ProductColumns} data={products}
              enableRowActions
              positionActionsColumn="last"
              getRowId={(row) => row.id}
              renderRowActions={({ row }) => (
                <Box>
                  <Button onClick={() => { dispatch(deleteCompany({ id: row.id, isDeleted: true })) }}>{t('delete')}</Button>
                </Box>
              )}
            />) : (
            <div className='card p-5'>
              <div className='d-flex justify-content-center align-items-center'>
                <h3 style={{ color: "GrayText" }}>{t('have-not-product')}</h3>
              </div>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <Link to='/product/add-product' className='btn btn-primary'>{t('add-product')}</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const ProductListWrapper: FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{t('companies')}</PageTitle>
      <ProductList />
    </>
  )
}

export { ProductListWrapper }
