/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { addCompany } from '../../../../redux/slices/CompanySlice';
import Input from '../../../component/InputComponent';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

const AddCompany: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const { isSuccess } = useSelector((store: any) => store.company)
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (isSuccess) {
      navigate('/company')
    }
  }, [isSuccess, navigate])

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          email: "",
          phoneNumber: 0,
          zipCode: "",
          address: "",
          cityId: "",
          countryId: "",
          iban: "",
          kvkNumber: 0,
          btwNumber: ""
        }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          values.phoneNumber = values.phoneNumber
            .replace(/\)/g, '')
            .replace(/\(/g, '')
            .replace(/-/g, '')
            .replace(/ /g, '')
          setTimeout(() => {
        resetForm();
          }, 2000)

      dispatch(addCompany(values))
        }}
      >
      {({ handleSubmit, handleChange, values, errors, touched, handleBlur, isSubmitting }) => (
        <form onSubmit={handleSubmit}>

          <div className='d-flex flex-column gap-5'>
            <div className='d-flex align-items-center gap-5'>
              <div className="form__group-design field">
                <label htmlFor="title">{t('company-name')}</label>
                <Input placeholder={t('company-name')} id='title' name='title' value={values.title} onChange={handleChange} onBlur={handleBlur} required />
              </div>
              <div className='form__group-design'>
                <label htmlFor="email">E-Mail</label>
                <Input id='email' name='email' placeholder="E-Mail" value={values.email} onChange={handleChange} onBlur={handleBlur} required />
              </div>
            </div>
            <div className='d-flex align-items-center gap-5'>
              <div className="form__group-design">
                <label htmlFor="phoneNumber">{t('phone-number')}</label>
                <PhoneInput
                  className='w-100'
                  defaultCountry="ua"
                  value={values.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                />
              </div>
              <div className='form__group-design'>
                <label htmlFor="zipCode">Posta Kodu</label>
                <Input id='zipCode' name='zipCode' placeholder={t('zip-code')} value={values.zipCode} onChange={handleChange} onBlur={handleBlur} required />
              </div>
            </div>

            <div className='d-flex align-items-center gap-5'>
              <div className="form__group-design field">
                <label className='form__label'>Ülke</label>
                <select className="custom-input-component selectpicker">
                  <option>Mustard</option>
                  <option>Ketchup</option>
                  <option>Relish</option>
                </select>
              </div>
              <div className="form__group-design field">
                <label className='form__label'>Şehir</label>
                <select className="custom-input-component selectpicker">
                  <option>Mustard</option>
                  <option>Ketchup</option>
                  <option>Relish</option>
                </select>
              </div>
            </div>

            <div className='d-flex align-items-center gap-5'>
              <div className="form__group-design field">
                <label htmlFor="iban">IBAN</label>
                <Input id='iban' name='iban' placeholder="IBAN" value={values.iban} onChange={handleChange} onBlur={handleBlur} required />
              </div>
              <div className='form__group-design'>
                <label htmlFor="kvkNumber">KVK Number</label>
                <Input id='kvkNumber' name='kvkNumber' placeholder="KVK Number" value={values.kvkNumber} onChange={handleChange} onBlur={handleBlur} required />
              </div>
            </div>

            <div className='d-flex align-items-center gap-5'>
              <div className="form__group-design field">
                <label htmlFor="btwNumber">BTW Number</label>
                <Input id='btwNumber' name='btwNumber' placeholder="BTW Number" value={values.btwNumber} onChange={handleChange} onBlur={handleBlur} required />
              </div>
              <div className='form__group-design'>
                <label htmlFor="address">Adress</label>
                <Input id='address' name='address' placeholder="Adress" value={values.address} onChange={handleChange} onBlur={handleBlur} required />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button className='btn btn-success' type='submit'>Save Company</button>
            </div>
          </div>
        </form>
      )}
    </Formik >
    </>
  )
}

const AddCompanyWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Add Company'}</PageTitle>
      <AddCompany />
    </>
  )
}

export { AddCompanyWrapper }
